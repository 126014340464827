
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  max-width: 100%;
  max-height: 100%;
  margin-top: 10px;
}

.button {
  background-color: #5C9D04; /* Green */
  border: none;
  color: white;
  border-radius: 20px;
  margin-left: 5px;
  margin-top: 10px;
  display: none;
}

.card {
  margin: 20px;
  padding: 20px;
  width: 90%;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}

label {
  display: inline-block;
  width: 40px;
}
input {
  padding: 5px 10px;
  border-radius: 20px;
}
.btn btn-success hide ,form-control hide{
  display: none;
}